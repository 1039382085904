<template>
  <div class="container mb-5">
    <div class="titre-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Echec</h1>
    </div>

    <div class="error-bloc">
      <div>
        <img
          src="../assets/img/non-dispo.svg"
          class="mt-4 img-non-dispo"
          alt="ordinateur portable affichant un point d'exclamation"
        />
      </div>
      <div class="mt-5">
        <span> Le lien a expiré. Veuillez renouveler votre demande.</span><br />

        <div class="container-btn-goToHome flex-column">
          <router-link
            class="fr-btn fr-btn--lg fr-btn--secondary mr-2 btn-rattacher mb-2"
            to="/"
            title="Retour à la page d'accueil"
            >Page d'accueil</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.error-bloc {
  padding: 3%;
  padding-right: 2%;
  border: 1px solid #dddddd;
  display: flex;
  font-size: 1.125rem;
}

.container-btn-goToHome {
  display: flex;
  padding-top: 0.5rem;
  height: 3.5rem;
  margin-top: 2rem;
}

.img-non-dispo {
  width: 90%;
}

@media only screen and (max-width: 760px) {
  .error-bloc {
    min-height: 26rem;
    flex-direction: column !important;
    padding-left: 1.5rem;
  }

  .container-btn-goToHome {
    display: block;
    width: 90%;
    padding-top: 0rem;
    height: 3.5rem;
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
</style>
